<template>
  <vCard :col="8" header="opgegeven reparaties" :actions="getExpectedDamagesActions">
    <h3>Optische schade</h3>
    <addExpectedDamageReport :type="$api.expectedDamageReport.OPTICAL" :dmg="opticalDamages"
                              :disabled="expectedDamagesDisabled"/>
    <h3>Technische schade</h3>
    <editExpectedDamageReport :type="$api.expectedDamageReport.TECHNICAL" :dmg="technicalDamages" :disabled="expectedDamagesDisabled"/>
  </vCard>
</template>


<script>
export default {
  name: 'purchaseList',
  created() {
    this.getExpectedDamages()
  },
  components: {
    editExpectedDamageReport: () => import('@/components/forms/expectedDamageReport/editExpectedDamageReport'),
    addExpectedDamageReport: () => import('@/components/forms/expectedDamageReport/addExpectedDamageReport')
  },
  data() {
    return {
      opticalDamages: [],
      technicalDamages: [],
      expectedDamagesDisabled: true,
      fuels: []
    }
  },
  computed: {
    getExpectedDamagesActions: {
      get() {
        if (this.expectedDamagesDisabled) {
          return [{
            icon: '<i class="material-icons">edit</i>', fn: () => {
              this.expectedDamagesDisabled = !this.expectedDamagesDisabled
            }
          }]
        } else {
          return [
            {
              icon: '<i class="material-icons">close</i>', fn: () => {
                this.expectedDamagesDisabled = !this.expectedDamagesDisabled
              }
            },
            {
              icon: '<i class="material-icons">save</i>', fn: () => {
                this.expectedDamagesDisabled = !this.expectedDamagesDisabled
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    async getVehicles() {
      let {data} = await this.$api.purchase.list()
    },
    async getExpectedDamages() {
      setTimeout(() => {
        this.technicalDamages = [
          {
            'id': 0,
            'type': 'optical',
            'description': 'Roest stuur',
            'purchase': 'string',
            'estimated_amount': 255
          },
          {
            'id': 0,
            'type': 'optical',
            'description': '',
            'purchase': 'string',
            'estimated_amount': 195.5
          }
        ]
      }, 200)
    }
  }
}
</script>